import questionnaires1 from "../../assets/tutorial/questionnaires/questionnaires.png";
import questionnaires2 from "../../assets/tutorial/questionnaires/questionnaires1.png";
import questionnaires3 from "../../assets/tutorial/questionnaires/questionnairesP.png";
import questionnaires4 from "../../assets/tutorial/questionnaires/questionnairesP3.png";
import questionnaires5 from "../../assets/tutorial/questionnaires/questionnairesP2.png";
import questionnaires6 from "../../assets/tutorial/questionnaires/questionnairesP4.png";
import questionnaires7 from "../../assets/tutorial/questionnaires/questionnairesP5.png";

export const questionnairesTutorialData = [
  {
    imageSrc: questionnaires1,
    subtitle: "Overview",
    description: [
      "This page displays the questionnaires that parents will complete. In the top right corner, there is a language selection option that allows you to display the translated titles.",
    ],
  },
  {
    imageSrc: questionnaires2,
    subtitle: "Questionnaire Preview",
    description: [
      "By selecting a questionnaire admins and operators can preview the questionnaire. Any answers will not be submitted for the respective roles. Only parent can answer them.",
    ],
  },
  {
    imageSrc: questionnaires3,
    subtitle: "Questionnaire Parent View",
    description: [
      "This is the view when a parent accesses the website.",
      "They can see how many times they have answered the questionnaire and the dates by hovering over the History button.",
    ],
  },
];

export const questionnairesTutorialDataStudent = [
  {
    imageSrc: questionnaires3,
    subtitle: "Overview",
    description: [
      "This page displays the questionnaires you will have to complete.",
      "In the top right corner, there is a language selection option that allows you to display the translated titles.",
      "You can see how many times you have answered the questionnaire, and the dates by hovering over the History button",
      "Note that if you have already answered any of these questionnaires by phone you do not have to answer them again online.",
    ],
  },
  {
    imageSrc: questionnaires4,
    description: ["You can also see if you have an incomplete questionnaire."],
  },
  {
    imageSrc: questionnaires5,
    subtitle: "Filling in a Questionnaire",
    description: [
      "By selecting a questionnaire, you can begin answering it. You may choose the language you wish to see the questions from the menu above.",
      "There is a Save Draft button if you'd like to complete it later. ",
      "Additionally, the page autosaves every few seconds, which is indicated at the top right of the page.",
    ],
  },

  {
    imageSrc: questionnaires6,
    description: [
      "When completeing a questionnaire be sure to wait until the questionnaire is saved.",
    ],
  },

  {
    imageSrc: questionnaires7,
    description: [
      "This message will appear when you are ready to close the application.",
    ],
  },
];
