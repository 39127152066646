import styles from "../../../styles/Survey.module.scss";
import {
  Box,
  Container,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Modal,
} from "@mui/material";
import { useAppSelector, useAppThunkDispatch } from "../../../store/hooks";
import {
  selectDrafts,
  selectQuestionnaires,
} from "../../../store/selectors/questionnaireSelector";
import {
  getDrafts,
  getQuestionnaires,
} from "../../../store/effects/questionnaireEffects";
import { useEffect, useState } from "react";
import { getParentStats } from "../../../services/api/pquestionnaires.api";
import { StyledButton } from "../../../components/styled/Button";
import {
  AllowAnswers,
  Draft,
  TemplateParentQuestionnaire,
} from "../../../types/questionnaire";
import { PermissionControl } from "../../../components/Permission";
import { getRole } from "../../../services/api/utils.api";
import { permissions } from "../../../utils/permissions";
import { questionnaires as content } from "../../../content/surveys";
import { Link } from "react-router-dom";
import { usePathname } from "../../../hooks/usePathname";
import { setAllowAnswers } from "../../../store/actions/questionnaireActions";
import Loading from "../../../components/Loading";
import { StyledFormControl } from "../../../components/styled/StyledFormSelect";
import { getCountries } from "../../../store/effects/countriesEffects";
import { handleApiError } from "../../../utils/handleApiError";
import AlertDialog from "../../../components/Alert";
import CustomSnackbar from "../../../components/Snackbar";
import { ROLES } from "../../../types/user";

type EnrichedTemplate = TemplateParentQuestionnaire & {
  frequency: number;
  dates: string[];
  open: boolean;
  draft: Draft | undefined;
  filter?: boolean;
};

const QuestionnairesPage = () => {
  const questionnaires = useAppSelector(selectQuestionnaires);
  const [isLoading, setIsLoading] = useState(false);
  const drafts = useAppSelector(selectDrafts);
  const [parentStats, setParentStats] = useState<any[]>();
  const [enrichedTemplates, setEnrichedTemplates] = useState<
    EnrichedTemplate[] | undefined
  >();
  const dispatch = useAppThunkDispatch();
  const pathname = usePathname();
  const role = getRole();

  const [error, setError] = useState<string | undefined>();

  const [lan, setLan] = useState("default");
  const changeLan = (e: SelectChangeEvent) => {
    setLan(e.target.value);
  };

  // console.log("Q: ", questionnaires);
  console.log("Content Qs: ", content);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        await Promise.all([
          dispatch(getQuestionnaires()),
          dispatch(getDrafts()),
          dispatch(getCountries()),
          getParentStats().then((res) => {
            console.log("Data: ", res.data.data);
            setParentStats(res.data.data);
          }),
        ]);
      } catch (e) {
        handleApiError(e, setError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (questionnaires && parentStats) {
      const allow_answers: AllowAnswers = {};
      const enrichedTemplates: EnrichedTemplate[] = questionnaires.map((q) => {
        const id = q._id;
        const response = parentStats;
        const frequency = (response[1][id] || 0) as number;
        const dates = (response[0][id] || []) as string[];
        const filter =
          role === "student" &&
          ((id === "1v2" && "1" in response[1]) ||
            (id === "1" && !("1" in response[1])) ||
            (id === "4v2" && "4" in response[1]) ||
            (id === "4" && !("4" in response[1])));

        // console.log(drafts);
        const draft = drafts.find((draft) => draft.questionnaire_id === q._id);

        allow_answers[id] = frequency < q.answersNum;

        return {
          ...q,
          frequency,
          dates,
          open: false,
          draft: draft,
          filter,
        };
      });
      dispatch(setAllowAnswers(allow_answers));
      console.log("Enriched", enrichedTemplates);

      const filteredEnrichedTemplates = enrichedTemplates.filter(
        (et) => et.filter !== true
      );

      setEnrichedTemplates(filteredEnrichedTemplates);
    }
  }, [questionnaires, parentStats]);

  // const [open, setOpen] = useState(false);

  // const handleTooltipClose = () => {
  //   setOpen(false);
  // };

  // const handleTooltipOpen = () => {
  //   setOpen(true);
  // };

  const getTranslatedText = () => {
    const messages = {
      default:
        "Important Notice: Please do not fill out any questionnaires that you have already completed by phone. Thank you!",
      fi: "Tärkeä ilmoitus: Älä täytä mitään kyselylomakkeita, jotka olet jo täyttänyt puhelimitse. Kiitos!",
      fr: "Avis important : Veuillez ne pas remplir de questionnaires que vous avez déjà complétés par téléphone. Merci !",
      ka: "მნიშვნელოვანი გაფრთხილება: გთხოვთ არ შეავსოთ რომელიმე კითხვარი, რომელიც უკვე ტელეფონით შეავსეთ. მადლობა!",
      gr: "Σημαντική ειδοποίηση: Μην συμπληρώνετε ερωτηματολόγια που έχετε ήδη συμπληρώσει τηλεφωνικά. Ευχαριστούμε!",
    };

    return messages[lan as keyof typeof messages] || messages.default;
  };

  if (isLoading) return <Loading />;

  return (
    <Container className={styles.surveyContainer} maxWidth={false}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
        }}
      >
        <StyledFormControl sx={{ minWidth: 200, maxWidth: 200 }}>
          <InputLabel id='select-lang'>Language</InputLabel>
          <Select
            labelId='lan'
            id='lan'
            value={lan}
            label='Language'
            onChange={(e) => changeLan(e)}
            size='small'
          >
            <MenuItem value={"default"}>English</MenuItem>
            <MenuItem value={"fi"}>Suomalainen</MenuItem>
            <MenuItem value={"fr"}>Français</MenuItem>
            <MenuItem value={"ka"}>ქართული</MenuItem>
            <MenuItem value={"gr"}>Ελληνικά</MenuItem>
          </Select>
        </StyledFormControl>
      </Box>
      <PermissionControl
        permissionPath={permissions.pages.questionnaires.access}
        role={getRole()}
      >
        <div className={styles.grid}>
          {enrichedTemplates &&
            enrichedTemplates.map((questionnaire, index) => (
              <div key={index} className={styles.card}>
                <Link
                  className={
                    questionnaire.frequency >= questionnaire.answersNum
                      ? styles.disabled
                      : ""
                  }
                  to={
                    questionnaire.draft
                      ? `${pathname}/${questionnaire._id}/${questionnaire.draft._id}`
                      : `${pathname}/${questionnaire._id}`
                  }
                >
                  <h3>
                    {
                      questionnaire.title[
                        lan as keyof typeof questionnaire.title
                      ]
                    }
                    &rarr;
                  </h3>
                  <p>
                    {
                      questionnaire.description[
                        lan as keyof typeof questionnaire.title
                      ]
                    }
                  </p>
                  <img
                    src={questionnaire.preview}
                    width={300}
                    height={200}
                    alt='Preview'
                  />
                </Link>
                <PermissionControl
                  permissionPath={permissions.pages.questionnaires.create}
                  role={getRole()}
                  route={false}
                >
                  <div className={styles.history}>
                    <p>
                      Answered: {questionnaire.frequency} /
                      {questionnaire.answersNum}
                    </p>
                    {questionnaire.dates.length > 0 && (
                      <Tooltip
                        // key = {index}
                        // PopperProps={{
                        //   disablePortal: true,
                        // }}
                        // // onClose={(e, questionnaire) => handleTooltipClose(e, questionnaire)}
                        // // onClose={handleTooltipClose}
                        // open={open}
                        // disableFocusListener
                        // disableHoverListener
                        // disableTouchListener
                        title={
                          <span>
                            {questionnaire.dates.map((d) => (
                              <p key={d}>{d}</p>
                            ))}
                          </span>
                        }
                      >
                        <StyledButton
                          className={styles.button}
                          variant='contained'
                          aria-label='history'
                          sx={{ cursor: "default" }}
                          // onClick={(event) => handleTooltipOpen(event, questionnaire)}
                          // onClick={handleTooltipOpen}
                        >
                          History
                        </StyledButton>
                      </Tooltip>
                    )}
                  </div>
                  {questionnaire.draft &&
                    questionnaire.frequency < questionnaire.answersNum && (
                      <span>Continue from previous draft</span>
                    )}
                </PermissionControl>
              </div>
            ))}
        </div>
      </PermissionControl>
      {error && (
        <AlertDialog
          title={"System Error"}
          message={error || ""}
          buttons={[
            {
              label: "Ok",
              onClick: () => {
                setError(undefined);
              },
            },
          ]}
        />
      )}
      <CustomSnackbar
        message={getTranslatedText()}
        show={role === ROLES.STUDENT}
        style={{ marginTop: "-100px" }}
      />
    </Container>
  );
};

export default QuestionnairesPage;
